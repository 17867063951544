.active_item {
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr 0.5fr 1fr 1fr 50px 50px;
}

.active_item:hover {
  cursor: pointer;
  background-color: #ebebeb;
}

.active_item div {
  overflow: hidden;
  max-width: 100%;
}

.active_item span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.work__status {
  grid-column-start: 1;
  justify-self: center;
}

.work__name {
  grid-column-start: 2;
}

.work__taskname {
  grid-column-start: 3;
}

.work__begin_date {
  grid-column-start: 4;
}

.work__progress {
  grid-column-start: 5;
}

.work__action_1 {
  grid-column-start: 6;
  justify-self: center;
}

.work__action_2 {
  grid-column-start: 7;
  justify-self: center;
}

.work__status img,
.work__action_1 img,
.work__action_2 img {
  justify-self: center;
  height: 25px;
  width: auto;
}

.work__status .img_enabled:hover,
.work__action_1 .img_enabled:hover,
.work__action_2 .img_enabled:hover {
  transform: scale(1.2);
}