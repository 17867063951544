.accessList {
  margin: 1rem 0;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  overflow: hidden;
}

.accessList__header {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: repeat(6, 1fr) 50px;
}

.accessList__read {
  grid-column-start: 2;
  justify-self: center;
}

.accessList__write {
  grid-column-start: 3;
  justify-self: center;
}

.accessList__create {
  grid-column-start: 4;
  justify-self: center;
}

.accessList__delete {
  grid-column-start: 5;
  justify-self: center;
}

.accessList__date {
  grid-column-start: 6;
  justify-self: center;
}

.access__item {
  border: 1px solid #ebebeb;
  padding: 0.5rem;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(6, 1fr) 50px;
}

.access__item:hover {
  background-color: #ebebeb;
}

.access__user {
  grid-column-start: 1;
  justify-self: center;
}

.access__read {
  grid-column-start: 2;
  justify-self: center;
}

.access__write {
  grid-column-start: 3;
  justify-self: center;
}

.access__create {
  grid-column-start: 4;
  justify-self: center;
}

.access__delete {
  grid-column-start: 5;
  justify-self: center;
}

.access__date {
  grid-column-start: 6;
  justify-self: center;
}

.access__remove {
  grid-column-start: 7;
  justify-self: center;
}

.access__remove img {
  justify-self: center;
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.access__remove img:hover {
  border: 3px solid #93a5d8;
}