.userList {
  margin-top: 1rem;
}

.user {
  border: 1px solid #ebebeb;
  padding: 1rem;
  align-items: center;
  display: grid;
  grid-template-columns: 50px 150px 1fr 150px repeat(2, 1fr);
}

.user:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.user div {
  overflow: hidden;
  max-width: 100%;
  padding: 0 5px;
}

.user span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.user__img {
  justify-self: center;
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.user__login {
  grid-column-start: 2;
}

.user__fio {
  grid-column-start: 3;
  padding: 0 20px;
}

.user__position {
  grid-column-start: 4;
  margin: 0 10px;
}

.user__roles {
  grid-column-start: 5;
}

.user__banned {
  grid-column-start: 6;
  justify-self: center;
}

.add__role_btn {
  height: 20px;
  width: auto;
  margin: 2px 5px
}

.add__role_btn:hover {
  border: 3px solid #93a5d8;
  border-radius: 50%;
}

.refresh_btn {
  height: 20px;
  width: auto;
}

.refresh_btn:hover {
  transform: scale(1.2);
  cursor: pointer;
}