@media all and (min-width: 480px) {
  .Login {
    padding: 7rem 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .Login form {
    /*margin: 0 auto;*/
    margin: 0 20px 20px 20px;
    max-width: 320px;
    border: 1px solid green;
    padding: 20px;
    border-radius: 20px;
  }
}
