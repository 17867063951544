.robot {
  border: 1px solid #ebebeb;
  padding: 0.5em;
  align-items: center;
  display: grid;
  grid-template-columns: 60px 4fr 60px 60px 50px;
}

.module {
  border: 1px solid #ebebeb;
  padding: 0.5em;
  align-items: center;
  display: grid;
  grid-template-columns: 60px 4fr 60px 50px;
}

.robot div,
.module div {
  overflow: hidden;
  max-width: 100%;
  padding: 0 5px;
}

.robot span,
.module span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.robotlist {
  margin-top: 1rem;
}


.robot:hover,
.module:hover {
  cursor: pointer;
  background-color: #ebebeb;
}

.module__share,
.robot__share {
  grid-column-start: 3;
  justify-self: center;
}

.robot__dowload {
  grid-column-start: 4;
  justify-self: center;
}

.robot__delete {
  grid-column-start: 5;
  justify-self: center;
}

.module__delete {
  grid-column-start: 4;
  justify-self: center;
}

.module__dowload img,
.module__delete img,
.module__share img,
.robot__dowload img,
.robot__share img,
.robot__owner img,
.robot__delete img {
  justify-self: center;
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.module__dowload .img_enabled:hover,
.module__delete .img_enabled:hover,
.module__share .img_enabled:hover,
.robot__dowload .img_enabled:hover,
.robot__delete .img_enabled:hover,
.robot__share .img_enabled:hover {
  transform: scale(1.2);
}

.module__img,
.robot__img {
  justify-self: center;
  height: 40px;
  width: auto;
  margin-right: 10px;
}