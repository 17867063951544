.group-list__container-item {
  border: 1px solid #ebebeb;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.group-list__empty-label {
  font-weight: bold;
}

.group-list__container-item-label {
  flex: 3;
}

.group-list__container-item-icon {
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.group-list__container-item-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.group-list__container-item-btn {
  flex: 1;
}

.group-list__add-group-btn {
  margin-top: 20px;
}

.add-group__container {
  margin-top: 50px;
}

.add-group__submit-btn {
  margin-top: 20px;
}



.grouplist__header {
  display: grid;
  grid-template-columns: 60px 4fr repeat(5, 1fr);
  color: #93a5d8;
  margin-bottom: 10px;
}

.grouplist__name {
  grid-column-start: 2;
}

.grouplist__action5 {
  grid-column-start: 5;
  justify-self: center;
}

.grouplist__action6 {
  grid-column-start: 6;
  justify-self: center;
}

.group {
  border: 1px solid #ebebeb;
  padding: 0.5em;
  align-items: center;
  display: grid;
  grid-template-columns: 60px 4fr repeat(5, 1fr);
}

.group:hover {
  border: 3px solid #ebebeb;
  margin: -2px;
  cursor: pointer;
}

.group__img {
  justify-self: center;
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.group__action3 {
  grid-column-start: 3;
  justify-self: center;
}

.group__action4 {
  grid-column-start: 4;
  justify-self: center;
}

.group__action5 {
  grid-column-start: 5;
  justify-self: center;
}

.group__action6 {
  grid-column-start: 6;
  justify-self: center;
}

.group__action7 {
  grid-column-start: 7;
  justify-self: center;
}

.group__action_icon {
  justify-self: center;
  height: 30px;
  width: auto;
  margin-right: 10px;
}

.group__action_icon:hover {
  border: 3px solid #93a5d8;
}

.crooss_icon {
  height: 23.43px;
  width: auto;
  margin-left: 10px;
}

.crooss_icon:hover {
  border: 3px solid #93a5d8;
}